import React, { useState } from "react";
import IconButton from "../../button/iconButton";
import trash from "../../../assets/icones/global/trash_can_red.svg";
import eye from "../../../assets/icones/global/eye.svg";
import importFile from "../../../assets/icones/global/import.svg";
import ModalFilesDeleting from "../../modal/modalDeleting/modalFilesDeleting";
import ModalImagePreview from "../../modal/modalImagePreview";
import LoaderAbsolute from "../../loader/loaderAbsolute";

// This component is modeled after your InputImages component but adapted for a single image.
const InputCoverImage = ({ name, onChange, error }) => {
    const [coverImage, setCoverImage] = useState(null);

    // For modals
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

    // Handle file selection for a single cover image.
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            // (Optional) Validate file type and size here if needed.
            const previewUrl = URL.createObjectURL(file);
            const newImage = { file, preview: previewUrl };

            setCoverImage(newImage);
            if (onChange) {
                onChange(newImage);
            }

        }
    };

    // Delete the currently selected image.
    const handleDeleteImage = () => {
        setCoverImage(null);
        if (onChange) {
            onChange(null);
        }
    };

    return (
        <div className={`input__files__container ${error ? "errorImg" : ""}`}>

            {
                !coverImage ?
                <div className={"form__col col__files"}>
                    <div className={"input__files__custom"}>
                        <img src={importFile} alt="Importer une image"/>
                        <div className="input__files__custom__text">
                           <p className="text-14">
                                <span className="text-14 bold">Cliquez</span> ou glisser-déposer
                            </p>
                            <span className="text-14">PNG, JPG, WEBP (max 10 Mo)</span>
                        </div>
                        <input
                            type="file"
                            name={name}
                            onChange={handleFileChange}
                            accept="image/png, image/jpeg, image/webp"
                        />
                    </div>
                </div>

                :
                <div className="files-added">
                    <ModalImagePreview
                        isOpen={isPreviewModalOpen}
                        closeModal={() => setIsPreviewModalOpen(false)}
                        image={coverImage ? coverImage.preview : ""}
                    />
                    <div className="files-image-preview">
                        <img
                            src={coverImage.preview}
                            alt="Prévisualisation"
                            style={{maxWidth: "200px"}}
                        />
                        <div className="cover-image-actions">
                            <IconButton
                                icon={trash}
                                onClick={() => handleDeleteImage()}
                            />
                        </div>
                    </div>
                </div>
            }

        </div>
    );
};

export default InputCoverImage;
