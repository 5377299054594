import React, { useState, useEffect, useCallback } from "react";
import HeaderView from "../../../components/header/headerView";
import TabBar from "../../../components/navbar/tabBar";
import ActivitiesParentTypesServices from "../../../api/services/activitiesParentTypes";
import "../../../styles/views/typeActivityManager.css";
import closeIcon from "../../../assets/icones/global/close-stroke.svg";
import IconButton from "../../../components/button/iconButton";
import addIcon from "../../../assets/icones/global/add.svg";
import ModalAddParentType from "../../../components/modal/modalSending/ModalAddParentType";
import { useLoader } from "../../../context/loaderContext";

const ParentTypeActivityManager = () => {
    const [parentTypes, setParentTypes] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const { setLoading } = useLoader();

    // Fetch all parent types on mount
    const fetchParentTypes = useCallback(async () => {
        try {
            const response = await ActivitiesParentTypesServices.getAllParentTypes();
            if (response.status === 200) {
                setParentTypes(response.data);
            }
        } catch (error) {
            console.error("Error fetching parent types:", error);
        }
    }, []);

    useEffect(() => {
        fetchParentTypes();
    }, [fetchParentTypes, reload]);

    // Delete a parent type and update the state.
    // (Assuming you already have a handler that deletes the parent type and sets the parentCategory field to null in related activity types.)
    const handleDeleteTypeActivity = useCallback(async (parentTypeId) => {
        setLoading(true);
        try {
            const response = await ActivitiesParentTypesServices.deleteParentType(parentTypeId);
            if (response.status === 200) {
                setParentTypes((prevTypes) => prevTypes.filter((item) => item._id !== parentTypeId));
            }
        } catch (error) {
            console.error("Error deleting parent type:", error);
        } finally {
            setLoading(false);
        }
    }, [setLoading]);

    // Add a new parent type
    const handleAddParentType = useCallback(async (newParentData) => {
        if (!newParentData.name || !newParentData.name.trim()) {
            console.error("Le nom est vide. Veuillez saisir un nom.");
            return;
        }

        setLoading(true);
        try {
            const response = await ActivitiesParentTypesServices.createParentType({ name: newParentData.name });
            if (response.status === 200) {
                setParentTypes((prevTypes) => {
                    const updatedList = [...prevTypes];
                    // Optionally insert before an item named "Autre" if needed.
                    const autreIndex = updatedList.findIndex((item) => item.name === "Autre");
                    if (autreIndex > -1) {
                        updatedList.splice(autreIndex, 0, response.data);
                    } else {
                        updatedList.push(response.data);
                    }
                    return updatedList;
                });
            }
        } catch (error) {
            console.error("Error adding parent type:", error);
        } finally {
            setLoading(false);
        }
    }, [setLoading]);

    return (
        <div className="main-container is__grey">
            <HeaderView
                title="Gestionnaire"
                isGoBack={false}
                actions={
                    <IconButton
                        icon={addIcon}
                        text="Ajouter une catégorie parente"
                        onClick={() => setModalIsOpen(true)}
                    />
                }
            />

            <TabBar
                tabs={[
                    { name: "Type d'activitée", link: `/manager` },
                    { name: "Catégories parentes", link: `/manager/parents` },
                ]}
                activeTab={1}
            />

            <div className="settings-container">
                <h2 className="title-18">Catégories parentes</h2>
                <div className="settings-container__content">
                    <div className="type__list_no-grid">
                        {parentTypes.length === 0 ? (
                            <p className="text-16">Aucune catégorie parente ajoutée</p>
                        ) : (
                            parentTypes.map((item) => (
                                <div className="type__list__item_simple" key={item._id}>
                                    <div className="type__delete">
                                        <img
                                            src={closeIcon}
                                            alt="delete"
                                            onClick={() => handleDeleteTypeActivity(item._id)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                    <p className="text-16">{item.name}</p>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>

            <ModalAddParentType
                isOpen={modalIsOpen}
                closeModal={() => setModalIsOpen(false)}
                addParentType={handleAddParentType}
                text="Ajouter une catégorie parente"
            />
        </div>
    );
};

export default ParentTypeActivityManager;
