import React, { useContext, useEffect, useState } from 'react';
import { ErrorContext } from "../../context/errorContext";
import { useParams } from "react-router";
import { useLoader } from "../../context/loaderContext";
import BookingInvoicesService from "../../api/services/bookingInvoices";
import logo from "../../assets/images/svg/logo_row.svg";
import "../../styles/views/bookingInvoice.css";
import useParseDate from "../../hooks/useParseDate";

const GenerateProofOfBookingUser = () => {
    const { bookingIdentifier, bookingAppId } = useParams();
    const { setLoading } = useLoader();
    const { showError } = useContext(ErrorContext);
    const { parseBookingDate, parseNotificationsDate } = useParseDate();

    const [bookingInfos, setBookingInfos] = useState(null);

    useEffect(() => {
        setLoading(true);
        BookingInvoicesService.generateProofOfBookingUser(bookingIdentifier, bookingAppId)
            .then((response) => {
                if (response.status === 200) {
                    setBookingInfos(response.data);
                }
            })
            .catch((error) => {
                showError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [bookingIdentifier, bookingAppId, setLoading, showError]);

    // Early return if no bookingInfos yet
    if (!bookingInfos) return null;

    // Destructure main fields for easier usage
    const {
        bookingUser,
        bookingDate,
        bookingIdentifier: bId,
        bookingAmount,
        bookingPricingDetails,
        bookingPersonNumber,
        activityID
    } = bookingInfos;

    return (
        <div className="container-invoice">
            {/* Logo / Header */}
            <img className="logo" src={logo} alt="logo"/>

            <div className="invoice-header">
                <h1 className="title-20">Numéro de réservation</h1>
                <p className="text-16">{bId}</p>
            </div>


            {/* User + Booking Basics */}
            <div className="invoice-section activity-infos">
                <div className="infos-column">
                    <div className="infos-img">
                        <img src={activityID?.activityImages?.url} alt="activity"/>
                    </div>

                    <div className="infos-content">
                        <p className="text-16">
                            <strong>Activité :</strong> {activityID.name}
                        </p>
                        <p className="text-16">
                            <strong>Date d'activité :</strong> {parseBookingDate(bookingDate)}
                        </p>
                        <p className="text-16">
                            <strong>Participants :</strong> {bookingPersonNumber}
                        </p>
                        <p className="text-16">
                            <strong>Montant total :</strong> {bookingAmount} €
                        </p>
                    </div>
                </div>
            </div>

            <div className="invoice-section paiements">
                <h2 class={"title-section"}>Informations sur l'organisateur</h2>
                <div className="infos-col">
                    <p className="text-16"><strong>Nom:</strong> {bookingUser.firstname} {bookingUser.lastname}</p>
                    <p className="text-16"><strong>Email:</strong> {bookingUser.email}</p>
                    <p className="text-16"><strong>Téléphone:</strong> {bookingUser.phoneNumber}</p>
                </div>
            </div>

            {/* Main Invoice Section */}
            <div className="invoice-section paiements">
                <h2 class={"title-section"}>Justificatif de paiement</h2>

                <div className="infos-col">

                    {/* Pricing Details */}
                    <div className="infos-column paiements">
                        <p className="text-16"><strong>Produit(s) :</strong></p>
                        {bookingPricingDetails.map((pricing, index) => (
                            <div key={index} className="infos-row">
                                <p className="text-16">
                                    x{pricing.pricingQuantity} {pricing.pricingName}
                                </p>
                                <p className="text-16">{pricing.pricingAmount} €</p>
                            </div>
                        ))}
                    </div>

                    {/* Separation & Total */}
                    <div className="separationLine"></div>
                    <div className="infos-row">
                        <h3>Montant total payé</h3>
                        <h3>{bookingAmount} €</h3>
                    </div>
                </div>

                {/* Disclaimers */}
                <p className="text-16">
                    Ce paiement d'une valeur de <strong>{bookingAmount}€</strong> a bien
                    été reçu et vous sera transmis une fois l'activité terminée.
                </p>

            </div>

            <div className="invoice-warning">
                <p className="text-16">
                    <em>
                        Ce document ne peut être utilisée afin de percevoir la TVA.<br/>
                        Veuillez noter que celui-ci est uniquement une preuve de réservation.
                        Il ne peut être utilisé pour des fins fiscales.
                    </em>
                </p>
            </div>
            {/* Footer */}
            <div className="invoice-footer">
                <h2>Frimity SAS</h2>
                <div className="infos-grid">
                    <div className="infos-col">
                        <p className="text-14">Siège social: 17 avenue de la Belle Gabrielle - 91390, Morsang-sur-orge</p>
                        <p className="text-14">Siret: 93033369500011</p>
                        <p className="text-14">RCS: Evry B 930 333 695</p>
                    </div>
                    <div className="infos-line"></div>
                    <div className="infos-col">
                        <p className="text-14">Téléphone : (+33) 0669931543</p>
                        <p className="text-14">Email : contact@frimity.fr</p>
                        <p className="text-14">Site web : frimity.app</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateProofOfBookingUser;
