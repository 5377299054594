import HeaderView from "../../../components/header/headerView";
import React, {useContext, useEffect, useState} from "react";
import InputSelect from "../../../components/form/inputSelect/inputSelect";
import BookingManageSchedule from "../bookingSidebar/bookingManageSchedule";
import ActivitiesServices from "../../../api/services/activities";
import {ErrorContext} from "../../../context/errorContext";
import {useLoader} from "../../../context/loaderContext";
import {useParams} from "react-router";
import BookingUsersService from "../../../api/services/bookingUsers";
import Table from "../../../components/table/table";
import person from "../../../assets/icones/global/user.svg";
import calendar from "../../../assets/icones/global/calendar.svg";
import group from "../../../assets/icones/global/users.svg";
import card from "../../../assets/icones/global/credit_card.svg";
import time from "../../../assets/icones/global/timer_white.svg";
import {useSelector} from "react-redux";
import {usePartner} from "../../../context/partnerContext";
import useParseDate from "../../../hooks/useParseDate";
import Tag from "../../../components/tag/tag";
import Button from "../../../components/button/button";
import useStatusName from "../../../hooks/parseText/useStatusName";
import ModalBookingDeleting from "../../../components/modal/modalDeleting/modalBookingDeleting";
import ModalBookingAdd from "../../../components/modal/modalSending/modalBookingAdd";
import SingleDateRangePicker from "../../../components/calendar/singleView/singleDateRangePicker";
import IconButton from "../../../components/button/iconButton";
import RemainingTime from "../../../components/calendar/timer/remainingTime";
import BookingUserDetails from "../bookingSidebar/bookingUserDetails";



const BookingManageBookingUser = () => {

    const partnerId = useParams().partnerId;
    const [openManageSchedule, setOpenManageSchedule] = useState(false);
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();
    const [refresh, setRefresh] = useState(true);
    const {parseBookingDate, parseNotificationsDate} = useParseDate();
    const { getStatusName } = useStatusName();

    const user = useSelector(state => state.auth.user);
    const { currentPartnerId, allPartners } = usePartner();
    const currentPartnerBooking = (() => {
        if (user.role === "partner") {
            // Safely access `partnerID` and `bookingPartnersID`
            const partner = user.partnerID?.find((partner) => partner._id === currentPartnerId);
            return partner?.bookingPartnersID || null; // Default to `null` if not found
        }

        // For other roles, access `allPartners`
        const partner = allPartners?.find((partner) => partner._id === partnerId);
        return partner?.bookingPartnersID || null; // Default to `null` if not found
    })();


    const [partnerActivities, setPartnerActivities] = useState([]);
    const [bookingUsers, setBookingUsers] = useState([]);
    const [bookingNumberName, setBookingNumberName] = useState([]);

    const [bookingToModify, setBookingToModify] = useState(null);
    const [bookingDetails, setBookingDetails] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState({
        isOpen: false,
        status: "",
        text: "",
        description: "",
        booking: null
    });
    const [openAcceptModal, setOpenAcceptModal] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [openDetailsModalMobile, setOpenDetailsModalMobile] = useState(false);
    const [currentBookingSelected, setCurrentBookingSelected] = useState(null);

    const [dateSelected, setDateSelected] = useState(null);
    const [bookingStatusSelected, setBookingStatusSelected] = useState("all");
    const [activitySelected, setActivitySelected] = useState(null);

    const [bookingAnalytics, setBookingAnalytics] = useState([
        {id: 1, name: "En attente", field: ["in-progress"], value: 0},
        {id: 3, name: "Activités réservées", field: ["booked"], value: 0},
        {id: 2, name: "Activités effectuées", field: ["completed"], value: 0},
        {id: 4, name: "Activités refusée / annulée", field: ["cancelled", "pastDate", "user-cancelled", "cancelledForReason"], value: 0}
    ]);

    const calendarInfos = [
        {id: 1, name: "Réservé", className: "yellow"},
        {id: 2, name: "À confirmer", className: "small yellow"},
    ];

    const [filteredBookingUsers, setFilteredBookingUsers] = useState([]);
    const [mediaQuery, setMediaQuery] = useState(window.matchMedia('(max-width: 991px)').matches);



    useEffect(() => {
        // Function to handle fetching data
        const fetchData = async () => {
            setLoading(true);
            // Fetch Partner Activities
            try {
                const activitiesResponse = await ActivitiesServices.getActivitiesByPartnerId(partnerId);
                if (activitiesResponse.status === 200) {

                    setPartnerActivities(activitiesResponse.data.reverse());
                }
            } catch (error) {
                showError(error);
            }

            // Fetch Booking Users
            try {
                const bookingUsersResponse = await BookingUsersService.getBookingUserByBookingPartnerId(currentPartnerBooking._id);
                if (bookingUsersResponse.status === 200) {
                    setBookingUsers(bookingUsersResponse.data.reverse());
                    setFilteredBookingUsers(bookingUsersResponse.data.reverse())

                    //fille bookingAnalytics values with bookingUsersResponse.data
                    setBookingAnalytics(bookingAnalytics.map((item) => {
                        return {
                            ...item,
                            value: bookingUsersResponse.data.filter((booking) => item.field.includes(booking.bookingStatus)).length
                        }
                    }));
                }
            } catch (error) {
                showError(error);
            }
            setLoading(false);
        };


        if (refresh && currentPartnerBooking) {
            fetchData();
            setBookingToModify(null);
            setRefresh(false);
        }
    }, [partnerId, refresh, currentPartnerBooking, setLoading, showError]);


    const handleStatusChange = ({status, reason}) => {
        const data = {
            status: status,
            cancellationReason: reason
        };

        BookingUsersService.updateBookingUserStatus(currentBookingSelected, data)
        .then((response) => {
            if(response.status === 200){
                setRefresh(true);
            }
        })
        .catch((error) => {
            showError(error);
        });
    }


    useEffect(() => {
        if(bookingUsers && bookingUsers.length > 0){
            let filtered = bookingUsers;

            if(bookingStatusSelected !== "" && bookingStatusSelected !== "all"){
                filtered = filtered.filter((booking) => booking.bookingStatus === bookingStatusSelected);
            }

            if(activitySelected){
                filtered = filtered.filter((booking) => booking.activityID === activitySelected);
            }

            if(dateSelected !== null){
                filtered = filtered.filter((booking) => new Date(booking.bookingDate).toDateString() === new Date(dateSelected).toDateString());
            }

            setFilteredBookingUsers(filtered);
        }
    }, [bookingStatusSelected, activitySelected, dateSelected]);

    useEffect(() => {
        setDateSelected(null);
    }, []);


    const renderRow = (booking, activityBooking) => {

        return !mediaQuery ? (
            <div className={"table__booking"}>
                <img src={activityBooking.activityImages.images[0]} alt={activityBooking.name}
                     className={"table__booking__img"}/>
                <div className={"table__booking__col"}>
                    <div className={"table__booking__row__infos table__booking__row__infos__wrap"} style={{width: "100%", justifyContent: "space-between"}}>
                        <div className={"table__booking__col__infos"}>
                            <p className={"text-16 bold"}>{activityBooking.name}</p>
                            <p className={"text-16 bold"}>{booking.bookingIdentifier}</p>
                        </div>
                        <div className={"table__booking__col__actions"}>
                        {
                            //check current time is in the range of 24 hours before the booking date cancellationLimit
                            booking.bookingStatus === "in-progress" && new Date() < new Date(booking.cancellationLimit) && new Date() >= new Date(new Date(booking.cancellationLimit).getTime() - 86400000) ?
                                <RemainingTime createdAt={booking.cancellationLimit}/>
                                :
                                <p className={"text-14 text_grey"}>{parseNotificationsDate(booking.createdAt)}</p>
                        }
                        </div>
                    </div>
                    <div className={"table__booking__row__infos table__booking__row__infos__wrap"}>
                        <div className={"table__booking__infos"}>
                            <img src={person} alt={"person"}/>
                            <p className={"text-14 text_grey"}>{booking.bookingUser.firstname} {booking.bookingUser.lastname}</p>
                        </div>
                        <div className={"table__booking__separation_line"}/>
                        <div className={"table__booking__infos"}>
                            <img src={calendar} alt={"person"}/>
                            <p className={"text-14 text_grey"}>{parseBookingDate(booking.bookingDate)}</p>
                        </div>
                        <div className={"table__booking__separation_line"}/>
                        <div className={"table__booking__infos"}>
                            <img src={group} alt={"person"}/>
                            <p className={"text-14 text_grey"}>{booking.bookingPersonNumber} {booking.bookingPersonNumber > 1 ? "personnes" : "personne"}</p>
                        </div>
                        <div className={"table__booking__separation_line"}/>
                        <div className={"table__booking__infos"}>
                            <img src={card} alt={"person"}/>
                            <p className={"text-14 text_grey"}>{booking.bookingAmount} €</p>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={"table__booking"}>
                <img src={activityBooking.activityImages.images[0]} alt={activityBooking.name} className={"table__booking__img"}/>
                <div className={"table__booking__col"}>
                    <div className={"table__booking__col__header"}>
                        <p className={"text-14 bold"}>{activityBooking.name}</p>
                        {
                            //check current time is in the range of 24 hours before the booking date cancellationLimit
                            booking.bookingStatus === "in-progress" && new Date() < new Date(booking.cancellationLimit) && new Date() >= new Date(new Date(booking.cancellationLimit).getTime() - 86400000) ?
                                <RemainingTime createdAt={booking.cancellationLimit}/>
                                :
                                <p className={"text-12 text_grey"}>{parseNotificationsDate(booking.createdAt)}</p>
                        }
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={"main-container is__grey"}>
            <HeaderView title={`Vos réservations`} isGoBack={false}
                        actions={<>
                            <IconButton text={`Réservations en attente : ${bookingAnalytics[0].value}`} isDark={true}
                                        icon={time}/>
                        </>
                        }
            />

            <div className={"booking__container__header"}>
                {
                    bookingAnalytics.map((item, index) => (
                        <div key={index} className={"booking__container__analytics__item"}>
                            <div className={"text-18 bold"}>{item.name}</div>
                            <div className={"text-16"}>{item.value}</div>
                        </div>
                    ))
                }
            </div>

            <div className={"booking__container__actions"}>
                <div className={"container__action_item"}>

                </div>
                <div className={"container__action_item"}>
                    <InputSelect
                        name={"bookingStatus"}
                        options={[
                            {id: 1, name: "Réservée", value: "booked"},
                            {id: 2, name: "Refusé", value: "cancelled"},
                            {id: 3, name: "Réalisé", value: "completed"},
                            {id: 4, name: "En attente", value: "in-progress"},
                            {id: 5, name: "Anulée", value: "pastDate"}
                        ]}
                        valueSelected={bookingStatusSelected}
                        placeholder={"Tous les status"}
                        onChange={(e) => setBookingStatusSelected(e.target.value)}
                    />

                    <InputSelect
                        name={"partnerActivities"}
                        options={partnerActivities.map((activity) => ({
                            id: activity._id,
                            name: activity.name,
                            value: activity._id
                        }))}
                        valueSelected={activitySelected}
                        placeholder={"Toutes les activités"}
                        onChange={(e) => setActivitySelected(e.target.value)}
                    />

                </div>
            </div>

            <div className={"booking__container__schedule"}>

                <div className={"schedule__container__calendar"}>
                    <div className={"schedule__calendar__datepicker"}>
                        <SingleDateRangePicker setDateSelected={setDateSelected}
                                               arrayDates={bookingUsers.map(booking => ({
                                                   date: booking.bookingDate,
                                                   status: booking.bookingStatus
                                               }))} isBooking={true} />
                    </div>
                    <div className={"schedule__calendar__infos"}>
                        {
                            calendarInfos.map((info) => (
                                <div className={"schedule__calendar__infos__item"}>
                                    <div
                                        className={`schedule__calendar__infos__item__pastille ${info.className}`}></div>
                                    <p className={"text-14"}>{info.name}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className={"booking__container__list"}>
                    {
                        filteredBookingUsers && filteredBookingUsers.length > 0 ?
                            <Table
                                columns={
                                    !mediaQuery ?
                                    [
                                        { name: "Activités", align: "left", width: "65%", field: "activity" },
                                        { name: mediaQuery ? "Statut" : "Statut de la réservation", align: "center", width: "25%", field: "status",
                                            styleTableItem: {
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }
                                        },
                                        { name: "Actions", align: "center", width: "10%", field: "actions" }
                                    ] : [
                                        { name: "Activités", align: "left", width: "65%", field: "activity" },
                                        { name: mediaQuery ? "Statut" : "Statut de la réservation", align: "center", width: "25%", field: "status",
                                            styleTableItem: {
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }
                                        },
                                    ]
                                }
                                data={
                                    filteredBookingUsers.map((booking) => {
                                        const activityBooking = partnerActivities.find((item) => item._id === booking.activityID);

                                        //check if current time is in range of bookingDate and bookingDate + bookingDuration
                                        const currentTime = new Date();
                                        const bookingStartTime = new Date(new Date(booking.bookingDate).getTime() - 2 * 60 * 60 * 1000);
                                        const bookingEndTime = new Date(bookingStartTime.getTime() + booking.bookingDuration * 60000);
                                        const isActivityInProgress = bookingStartTime <= currentTime && currentTime <= bookingEndTime;

                                        return {
                                            booking,
                                            activityBooking,
                                            activity: renderRow(booking, activityBooking),
                                            status: booking.bookingStatus === "in-progress" && !mediaQuery ? (
                                                <div className={"table__booking__row__actions"}>
                                                    <Button
                                                        text={"Accepter"}
                                                        isPrimary={true}
                                                        onClick={() => {
                                                            setCurrentBookingSelected(booking._id);
                                                            setOpenAcceptModal(true);
                                                        }}
                                                    />
                                                    <Button
                                                        text={"Refuser"}
                                                        isPrimary={false}
                                                        onClick={() => {
                                                            setCurrentBookingSelected(booking._id);
                                                            setOpenDeleteModal({
                                                                isOpen: true,
                                                                status: "cancelled",
                                                                text: "Refuser la réservation",
                                                                button: "Refuser la réservation",
                                                                description: "En refusant cette réservation, veuillez noter que cela pourrait impacter la satisfaction de l'utilisateur et votre réputation au sein de la communauté Frimity.",
                                                                booking: booking
                                                            });
                                                        }}
                                                    />
                                                    <p
                                                        className={"text-14 text_grey text_bold pointer"}
                                                        onClick={() => {
                                                            setBookingToModify({
                                                                activity: activityBooking,
                                                                booking: booking,
                                                            });
                                                            setOpenManageSchedule(true);
                                                        }}
                                                    >
                                                        Autre créneau
                                                    </p>
                                                </div>
                                            ) : booking.bookingStatus === "in-progress" && mediaQuery ? (
                                                <div className={"table__booking__row__actions"}>
                                                    <Button
                                                        text={"En attente"}
                                                        isPrimary={true}
                                                        onClick={() => {
                                                            setBookingDetails({
                                                                activity: activityBooking,
                                                                booking: booking,
                                                            });
                                                            setOpenDetailsModalMobile(true);
                                                        }}
                                                    />
                                                </div>
                                            ) : isActivityInProgress && booking.bookingStatus === "booked" && booking.bookingStatus !== "completed" ? (
                                                <Tag text={"En cours"} status={"progress"} />
                                            ) : (
                                                    <Tag text={getStatusName(booking.bookingStatus)} status={booking.bookingStatus} />
                                            ),
                                            actions: booking.bookingStatus !== "in-progress" && !mediaQuery && (
                                                <div className={"table__booking__row__actions"}>
                                                    <Button
                                                        text={"Voir les détails"}
                                                        isPrimary={false}
                                                        onClick={() => {
                                                            setBookingDetails({
                                                                activity: activityBooking,
                                                                booking: booking,
                                                            });
                                                            setOpenDetailsModal(true);
                                                        }}
                                                    />
                                                </div>
                                            ),
                                            rowClickable: booking.bookingStatus !== "in-progress", // Indicate if the row is clickable
                                        };
                                    })
                                }
                                onRowClick={(row, index) => {
                                    if (row.rowClickable) { // Only trigger if row is clickable
                                        const { booking, activityBooking } = row; // Extract the booking object
                                        if (!mediaQuery) {
                                            setBookingDetails({
                                                activity: activityBooking,
                                                booking: booking,
                                            });
                                            setOpenDetailsModal(true);
                                        }
                                    }
                                }}
                            />

                            :
                            <div className={"container__empty"}>
                                {
                                    dateSelected !== null ?
                                        <p className={"text-14"}>Vous n'avez pas de réservation avec vos critères de
                                            recherche</p>
                                        :
                                        <p className={"text-14"}>Vous n'avez pas de réservation avec vos critères de recherche</p>
                                }
                            </div>

                    }
                </div>
            </div>
            <ModalBookingAdd closeModal={() => setOpenAcceptModal(false)} isOpen={openAcceptModal} addBooking={() => handleStatusChange({ status: "booked"})} mediaQuery={mediaQuery} />

            <ModalBookingDeleting
                isOpen={openDeleteModal.isOpen}
                closeModal={() => setOpenDeleteModal({ ...openDeleteModal, isOpen: false })}
                deleteBooking={reason => handleStatusChange({ status: openDeleteModal.status, reason })}
                status={openDeleteModal.status}
                text={openDeleteModal.text}
                description={openDeleteModal.description}
                button={openDeleteModal.button}
                booking={openDeleteModal.booking}
                mediaQuery={mediaQuery}
            />

            <BookingManageSchedule open={openManageSchedule} setOpen={setOpenManageSchedule} bookingToModify={bookingToModify} setRefresh={setRefresh} mediaQuery={mediaQuery} />

            {/* SIDEBAR */}
            {
                !mediaQuery && bookingDetails &&
                <BookingUserDetails open={openDetailsModal} setOpen={setOpenDetailsModal} booking={bookingDetails} setRefresh={setRefresh}
                                    openCancelModal={() => setOpenDeleteModal({
                                        isOpen: true,
                                        status: "cancelledForReason",
                                        text: "Annuler la réservation",
                                        button: "Annuler la réservation",
                                        description: "En annulant cette réservation, veuillez noter que cela pourrait impacter la satisfaction de l'utilisateur et votre réputation au sein de la communauté Frimity.",
                                        booking: bookingDetails.booking
                                    })}
                                    setCurrentBookingSelected={setCurrentBookingSelected}
                />
            }

            { mediaQuery &&
                <ModalBottomDetailsResponsive
                    isOpen={openDetailsModalMobile}
                    closeModal={() => setOpenDetailsModalMobile(false)}
                    booking={bookingDetails}
                    setBookingToModify={setBookingToModify}
                    setOpenManageSchedule={setOpenManageSchedule}
                    setCurrentBookingSelected={setCurrentBookingSelected}
                    setOpenAcceptModal={setOpenAcceptModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                />
            }
        </div>
    );
}


const ModalBottomDetailsResponsive = ({isOpen, closeModal, booking, setBookingToModify, setOpenManageSchedule, setCurrentBookingSelected, setOpenAcceptModal, setOpenDeleteModal}) => {

    const { getStatusName } = useStatusName();
    const {parseBookingDate, parseNotificationsDate} = useParseDate();


    return (
        <div className={`bg__modal ${isOpen ? "open" : "close"}`}>
            <div className={`modal booking_bottom ${isOpen ? "open" : "close"}`}>
                <div className={"modal__header"}>
                    <p className={"title-20"}>Réservation</p>
                    <Tag text={getStatusName("in-progress")} status={"in-progress"}/>
                </div>

                <div className={"modal__content"}>

                    <div className={"modal__colum"}>
                        <p className={"text-14 light"}>Créneau</p>
                        <p className={"text-16 bold"}>{booking && booking.booking ? parseBookingDate(booking.booking.bookingDate) : ""}</p>
                    </div>

                    <div className={"modal__colum"}>
                        <p className={"text-14 light"}>Nombre de personnes</p>
                        <p className={"text-16 bold"}>{booking && booking.booking ? booking.booking.bookingPersonNumber : 0} personne(s)</p>
                    </div>

                    <div className={"modal__colum"}>
                        <p className={"text-14 light"}>Montant total de la réservation</p>
                        <p className={"text-16 bold"}>{booking && booking.booking ? booking.booking.bookingAmount : 0}€</p>
                    </div>


                    <div className={"modal__actions"}>
                        <Button text={"Accepter"} isPrimary={true} onClick={() => {
                            setCurrentBookingSelected(booking.booking._id);
                            closeModal();
                            setOpenAcceptModal(true);
                        }}/>
                        <Button text={"Refuser"} isPrimary={false} onClick={() => {
                            setCurrentBookingSelected(booking.booking._id);
                            closeModal();
                            setOpenDeleteModal(true);
                        }}/>
                        <p className={"text-14 text_grey text_bold pointer"}
                           onClick={() => {
                               setBookingToModify({
                                   activity: booking.activity,
                                   booking: booking.booking
                               });
                               closeModal();
                               setOpenManageSchedule(true);
                           }}>Autre créneau</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookingManageBookingUser;