export const formatAddress = (location) => {
    // Define the possible separators
    const separators = [', ', ' - ', ' '];

    // Find the appropriate separator
    let parts;
    for (let sep of separators) {
        if (location.includes(sep)) {
            parts = location.split(sep);
            break;
        }
    }

    // Extract the street, which is always the first part
    const street = parts[0];

    // The second part contains the zip code and the city, which we need to split further
    let zipCityPart;
    if (parts[1]) {
        // Check if there's a second part and it contains both zip and city
        zipCityPart = parts[1].split(' ');
    } else {
        // If there's no second part, use the remaining parts for zip and city
        zipCityPart = parts.slice(1).join(' ').split(' ');
    }

    const zipCode = zipCityPart[0];
    const city = zipCityPart.slice(1).join(' ');

    return { city: city, address: `${street}, ${zipCode}` };
}