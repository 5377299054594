import { useMemo } from 'react';

const useStatusName = () => {
    const getStatusName = (status) => {
        switch (status) {
            case 'active':
                return 'Actif';
            case 'review':
                return 'Référencés';
            case 'lost':
                return 'Perdu';
            case 'pending':
                return 'En cours de validation';
            case 'draft':
                return 'Brouillon';
            case 'error':
                return 'Information(s) manquante(s)';
            case 'archived':
                return 'Archivé';
            case 'ended':
                return 'Terminé';
            case 'inactive':
                return 'Pause';
            case 'waiting':
                return 'En cours de validation';
           case 'booked':
                return 'Réservé';
           case "cancelled":
                return 'Refusé';
           case "completed":
                return 'Réalisé';
           case "waiting-date":
                return 'En cours';
           case "user-cancelled":
                return 'Annulé par le client';
           case "cancelledForReason":
                return 'Annulé';

            // Adding Stripe Payment Intent statuses
            case 'requires_payment_method':
                return 'Nécessite un moyen de paiement';
            case 'requires_confirmation':
                return 'Nécessite une confirmation';
            case 'requires_action':
                return 'Action du client requise';
            case 'processing':
                return 'En traitement';
            case 'succeeded':
                return 'Réussi';
            case 'requires_capture':
                return 'Capture requise';
            case 'canceled':
                return 'Annulé';
            case 'refunded':
                return 'Remboursé';
            case 'in-progress' :
                return 'En cours';
            case 'pastDate':
                return 'Annulée (date passée)';
            case 'waiting-for-compensation':
                return 'Annulée (en attente de compensation)';
            case 'compensation-paid':
                return 'Annulée (compensation payée)';

            case 'complete':
                return 'Complété';
            case 'not-completed':
                return 'En attente d\'informations';
            case 'disabled':
                return 'Information(s) manquante(s)';
            case 'unused':
                return 'Non associé';

            case 'reversed':
                return 'Remboursé';
            case 'compensation':
                return 'Compensation';

           default:
                return 'Pas de status'; // Default value for unrecognized statuses
        }
    };

    return {
        getStatusName,
    };
};

export default useStatusName;
