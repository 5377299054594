import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';

const ActivitiesServices = {

    getActivities: async () => {
        return await getRequest('/activities');
    },

    getActivitiesToVerify: async () => {
        return await getRequest('/activities/all-activites/no-verify');
    },

    getActivityById: async (activityId) => {
        return await getRequest(`/activities/${activityId}`);
    },

    getActivitiesByPartnerId: async (partnerId) => {
        return await getRequest(`/activities/partner/${partnerId}`);
    },

    getActivitiesByVerification: async (partnerId, isVerified) => {
        return await getRequest(`/activities/partner/${partnerId}/${isVerified}`);
    },

    getPublicPartnerActivities: async (partnerId, usePublicToken = false) => {
        return await getRequest(`/activities/partner-public/${partnerId}`, {}, usePublicToken);
    },

    createActivity: async (partnerId, data) => {
        return await postRequestJson(`/activities/${partnerId}`, data);
    },

    saveDraftActivity: async (partnerId, data) => {
        return await postRequestJson(`/activities/draft/${partnerId}`, data);
    },

    duplicateActivity: async (partnerId, activityId) => {
        return await postRequestJson(`/activities/${partnerId}/${activityId}`);
    },

    updateActivity: async (activityId, partnerId, data) => {
        return await patchRequest(`/activities/${partnerId}/${activityId}`, data);
    },

    deleteActivity: async (activityId, partnerId) => {
        return await deleteRequest(`/activities/${partnerId}/${activityId}`);
    }

}

export default ActivitiesServices;