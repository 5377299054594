import React, { useState, useEffect } from "react";
import closeIcon from "../../../assets/icones/global/close-stroke.svg";
import Button from "../../button/button";
import Input from "../../form/inputText/input";



const ModalAddParentType = ({ isOpen, closeModal, addParentType, text }) => {
    const [parentTypeName, setParentTypeName] = useState("");

    useEffect(() => {
        if (!isOpen) {
            setParentTypeName("");
        }
    }, [isOpen]);

    const handleSend = () => {
        if (!parentTypeName || !parentTypeName.trim()) {
            console.error("Le nom est vide. Veuillez saisir un nom.");
            return;
        }
        addParentType({ name: parentTypeName });
        setParentTypeName("");
        closeModal();
    };

    return (
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal ${isOpen ? "open" : ""} addParentType`}>
                <div className="modal__header">
                    <div className="modal__header__text">
                        <p className="title-14">{text}</p>
                    </div>
                    <img
                        src={closeIcon}
                        alt="close"
                        onClick={closeModal}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="modal__content">
                    <Input
                        name="parentTypeName"
                        type="text"
                        label="Nom de la catégorie parente"
                        valueInput={parentTypeName}
                        onChangeForm={(e) => setParentTypeName(e.target.value)}
                        placeholder="Ex : Catégorie Parente"
                        isColumn={true}
                    />
                    <div className="modal__content__actions">
                        <Button text="Annuler" isPrimary={false} onClick={closeModal} />
                        <Button text="Envoyer" isPrimary={true} onClick={handleSend} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddParentType;
