import React, {useContext, useEffect, useState} from 'react';
import '../../styles/views/singleActivity.css';
import {useParams} from "react-router";
import HeaderView from "../../components/header/headerView";
import Form from "../../components/form/form";
import Images from "./formActivity/images";
import Name from "./formActivity/name";
import Subtitle from "./formActivity/subtitle";
import Schedule from "./formActivity/schedule";
import Category from "./formActivity/category";
import NbPersons from "./formActivity/nbPersons";
import Duration from "./formActivity/duration";
import Description from "./formActivity/description";
import PraticalInfos from "./formActivity/praticalInfos";
import Expected from "./formActivity/expected";
import CancelPolicy from "./formActivity/cancelPolicy";
import Included from "./formActivity/included";
import BookingPolicy from "./formActivity/bookingPolicy";
import Access from "./formActivity/access";
import PricingAndExtras from "./formActivity/pricingAndExtras";
import useParseActivity from "../../hooks/parseDataApi/useParseActivity";
import ToKnow from "./formActivity/toKnow";
import Insurance from "./formActivity/insurance";
import useFormValidation from "../../hooks/form/useFormValidation";
import useFormUpdate from "../../hooks/form/useFormUpdate";
import ModalVerification from "../../components/modal/modalInfos/modalVerification";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import PartnerSubscriptionServices from "../../api/services/partnerSubscription";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import Types from "./formActivity/types";
import {usePartner} from "../../context/partnerContext";
import IconButton from "../../components/button/iconButton";
import save from "../../assets/icones/global/save.svg";
import {useNavigate} from "react-router-dom";
import useActivitiesHandler from "../../hooks/serverActions/activities/useActivitiesHandler";
import ModalAccept from "../../components/modal/modalInfos/modalAccept";

const AddActivity = () => {

    const partnerId = useParams().partnerId;
    const activityId = useParams().activityId;
    const [subscription, setSubscription] = useState(null);
    const {parseActivityForApi, parseActivity, parseDraftActivity} = useParseActivity();
    const { validateForm } = useFormValidation();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isImageChecked, setIsImageChecked] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [errorIsUpdated, setErrorIsUpdated] = useState(false);

    const { currentPartnerId, allPartners } = usePartner();
    const user = useSelector(state => state.auth.user);
    const currentPartner = allPartners.find(partner => partner._id === currentPartnerId) || null;

    const { formState, setFormState, handleFormChange } = useFormUpdate(parseActivity([]));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [draftModal, setDraftModal] = useState(false);


    const {
        addActivity,
        saveDraftActivity
    } = useActivitiesHandler({ partnerId, user, setIsModalOpen, showError, setLoading });


    const generateReference = () => {
        const prefix = "#FRIM";
        const number = Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit number
        return `${prefix}${number}`;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isFormValid = validateForm(formState, setFormState);

        isImageChecked ? setImageError(false) : setImageError(true);
        isUpdated ? setErrorIsUpdated(false) : setErrorIsUpdated(true);

        if(isFormValid.isFormValid && isImageChecked && isUpdated){
            setLoading(true);
            const activityForApi = parseActivityForApi(formState);

            // Filter local images to upload
            const localImages = activityForApi.activityImages?.images?.filter(img => img.isLocal) || [];

            await addActivity(activityForApi, localImages);
        }
    };

    const handleSubmitDraft = async () => {
        if (!formState.name.value) {
            showError('Vous devez renseigner le nom de l\'activité.');
            return;
        }

        const draftData = parseDraftActivity(formState);
        const localImages = draftData.activityImages.images.filter((img) => img.isLocal);
        await saveDraftActivity(draftData, localImages);
    };

    // Handle refresh or tab close
    const handleBeforeUnload = (e) => {
        e.preventDefault();
        setDraftModal(true); // Show custom modal
    };

    useEffect(() => {
        // Listen for page unload
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);



    useEffect(() => {
       handleFormChange("reference.value", generateReference());

        //get subscription of partner
        PartnerSubscriptionServices.getSubscriptionByPartnerId(partnerId)
        .then((response) => {
            if(response.status === 200){
                setSubscription(response.data.subscriptionPlan);
            }else{
                setSubscription(false)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);


    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Ajouter une activitée"} goBackAction={() => validateForm(formState, setFormState) ? setDraftModal(true) : navigate(`/partners/${partnerId}/activities`)} />

            <div className={"header__container"}>
                <h1><span className={"text-18"}>Votre établissement :</span> { user.role === "partner" || user.role === "partners-admin" ? currentPartner.name : ""}</h1>
                <div className={"header__container__col"}>
                    <p className={"text-14"}>Ajouter une nouvelle activité</p>
                </div>
            </div>

            <div className={"main-activity__container"}>
                <div className={"main-activity__container__row"}>
                    <div className={"container__row"}>
                        {
                            formState.reference &&
                            <div className={"container__row__col"}>
                                <p className={"title-18"}>{formState.reference.value}</p>
                            </div>
                        }
                    </div>

                    <div className={"main-activity__container__buttons"}>
                        <IconButton icon={save} text={"Mettre en brouillon"} isPrimary={false} onClick={() => handleSubmitDraft(activityId)}/>
                    </div>
                </div>

                <Form
                    children={
                        formState &&
                        <>
                            <Images images={formState.images} setActivity={handleFormChange} type={"local"}
                                    activityId={activityId} isImageChecked={isImageChecked}
                                    setIsImageChecked={setIsImageChecked} imageError={imageError}/>
                            <Name name={formState.name} setActivity={handleFormChange}/>
                            {user.role === "admin" || user.role === "super-admin" && <Subtitle subtitle={formState.subtitle} setActivity={handleFormChange}/>}
                            <Types type={formState.type} setActivity={handleFormChange}/>
                            <Schedule mode={"add"} schedule={formState.schedule} setActivity={handleFormChange}/>
                            <Category category={formState.category} setActivity={handleFormChange}/>
                            <NbPersons nbPersons={formState.nbPersons} setActivity={handleFormChange}/>
                            <Duration duration={formState.duration} setActivity={handleFormChange}/>
                            <Description description={formState.description} setActivity={handleFormChange}/>
                            <PraticalInfos practicalInfo={formState.practicalInfo} setActivity={handleFormChange}/>
                            <Expected expected={formState.expected} setActivity={handleFormChange}/>
                            <ToKnow toKnow={formState.toKnow} setActivity={handleFormChange}
                                    toKnowInfos={formState.toKnowInfos} toKnowOther={formState.toKnowOther}/>
                            <BookingPolicy bookingPolicy={formState.bookingPolicy} setActivity={handleFormChange}/>
                            <PricingAndExtras pricingAndExtras={formState.pricingAndExtras}
                                              setActivity={handleFormChange}
                                              commission={currentPartner?.subscriptionID?.commission} />
                            <Access access={formState.access} setActivity={handleFormChange} isAdmin={user.role === "admin"}/>
                            <CancelPolicy cancel={formState.cancel} setActivity={handleFormChange}/>
                            <Included included={formState.included} setActivity={handleFormChange}/>
                            <Insurance insurance={formState.hasInsurance} setActivity={handleFormChange} isUpdated={isUpdated} setIsUpdated={setIsUpdated} errorIsUpdated={errorIsUpdated}/>
                        </>
                    }
                    onSubmit={handleSubmit}
                    mode={"sendValidation"}
                />


            </div>

            <ModalVerification isOpen={isModalOpen} link={`/partners/${partnerId}/activities`} type={"activitée"}
                               action={"ajoutée"}/>
            <ModalAccept
                isOpen={draftModal}
                closeModal={() => {
                    setDraftModal(false);
                    navigate(`/partners/${partnerId}/activities`);
                }}
                addOffer={handleSubmitDraft}
                title={"Quitter sans sauvegarder ?"}
                message={"Souhaitez-vous sauvegarder votre fiche en brouillon pour y revenir plus tard ?"}
            />
        </div>
    )
}

export default AddActivity;