import React, { useState, useEffect, useCallback, useMemo } from "react";
import HeaderView from "../../../components/header/headerView";
import TabBar from "../../../components/navbar/tabBar";
import ActivitiesTypesServices from "../../../api/services/activitiesTypes";
import ActivitiesParentTypesServices from "../../../api/services/activitiesParentTypes";
import "../../../styles/views/typeActivityManager.css";
import closeIcon from "../../../assets/icones/global/close-stroke.svg";
import IconButton from "../../../components/button/iconButton";
import addIcon from "../../../assets/icones/global/add.svg";
import editIcon from "../../../assets/icones/global/edit_black.svg";
import ModalAddTypeActivity from "../../../components/modal/modalSending/modalAddTypeActivity";
import { useLoader } from "../../../context/loaderContext";

const TypeActivityManager = () => {
    const [typeActivityList, setTypeActivityList] = useState([]);
    const [parentCategories, setParentCategories] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editType, setEditType] = useState(null); // For future editing functionality
    const [reload, setReload] = useState(false);
    const { setLoading } = useLoader();


    // Fetch all activity types from the API
    const fetchActivitiesTypes = useCallback(async () => {
        try {
            const response = await ActivitiesTypesServices.getActivitiesTypes();
            if (response.status === 200) {
                setTypeActivityList(response.data);
            }
        } catch (error) {
            console.error("Error fetching types:", error);
        }
    }, []);

    // Fetch all available parent categories
    const fetchParentCategories = useCallback(async () => {
        try {
            const response = await ActivitiesParentTypesServices.getAllParentTypes();
            if (response.status === 200) {
                setParentCategories(response.data);
            }
        } catch (error) {
            console.error("Error fetching parent categories:", error);
        }
    }, []);

    useEffect(() => {
        fetchActivitiesTypes();
        fetchParentCategories();
    }, [fetchActivitiesTypes, fetchParentCategories, reload]);

    // Delete a type and update the list
    const handleDeleteTypeActivity = useCallback(async (typeId) => {
        try {
            const response = await ActivitiesTypesServices.deleteActivitiesType(typeId);
            if (response.status === 200) {
                setTypeActivityList((prevList) =>
                    prevList.filter((item) => item._id !== typeId)
                );
            }
        } catch (error) {
            console.error("Error deleting type:", error);
        }
    }, []);

    const handleAddOrUpdateTypeActivity = async (editType, newTypeData) => {
        setLoading(true);
        if (!newTypeData.name || !newTypeData.name.trim()) {
            console.error("Le nom est vide. Veuillez saisir un nom.");
            setLoading(false);
            return;
        }
        // Create FormData for the cover image if provided
        const coverFile = new FormData();
        const cover = newTypeData.icon;

        if (cover) {
            let fileToUpload;

            if (cover instanceof File) {
                fileToUpload = cover;
            } else if (cover.file) {
                fileToUpload = cover.file;
            } else if (typeof cover === "string") {
                // If it's a URL string, fetch it and convert to a File
                const blob = await fetch(cover).then((res) => {
                    if (!res.ok) throw new Error("Failed to fetch file");
                    return res.blob();
                });
                fileToUpload = new File([blob], "cover", { type: blob.type || "image/png" });
            }
            coverFile.append("image", fileToUpload);
        }

        // Remove icon from newTypeData since we handle it separately
        delete newTypeData.icon;

        // Prepare the data object to send to the create or update endpoint
        const data = {
            ...newTypeData,
            parentCategory: newTypeData.parentCategory ? newTypeData.parentCategory : null,
        };

        try {
            // Call the appropriate endpoint based on whether we're editing or creating.
            const response = editType
                ? await ActivitiesTypesServices.updateActivitiesType(editType._id, data)
                : await ActivitiesTypesServices.createActivitiesType(data);

            if (response.status === 200) {
                if (coverFile.has("image")) {
                    const uploadResponse = await ActivitiesTypesServices.createOrUpdateTypeCover(
                        response.data._id,
                        coverFile
                    );
                    if (uploadResponse.status !== 200)
                        throw new Error("File upload failed");
                }
                setTypeActivityList((prevList) => {
                    const updatedList = [...prevList];
                    const typeIndex = updatedList.findIndex(
                        (item) => item._id === response.data._id
                    );
                    if (typeIndex > -1) {
                        updatedList[typeIndex] = response.data;
                    } else {
                        updatedList.push(response.data);
                    }
                    return updatedList;
                });
            }
        } catch (error) {
            console.error("Error adding type:", error);
        } finally {
            setLoading(false);
            setEditType(null);
            setModalIsOpen(false);
            setReload((prev) => !prev);
        }
    };

    // Group types by category
    const groupedTypes = useMemo(() => {
        return typeActivityList.reduce((groups, type) => {
            const categoryName =
                type.parentCategory && type.parentCategory.name
                    ? type.parentCategory.name
                    : "Sans catégorie parentes";
            if (!groups[categoryName]) {
                groups[categoryName] = [];
            }
            groups[categoryName].push(type);
            return groups;
        }, {});
    }, [typeActivityList]);

    // Sort the grouped categories so that "Sans catégorie parentes" appears first.
    const sortedCategoryKeys = useMemo(() => {
        const keys = Object.keys(groupedTypes);
        return keys.sort((a, b) => {
            if (a === "Sans catégorie parentes") return -1;
            if (b === "Sans catégorie parentes") return 1;
            return a.localeCompare(b);
        });
    }, [groupedTypes]);

    const renderItemCard = (item) => (
        <div className="type__list__item" key={item._id}>
            <div className="type__actions">
                <div className="type__actions__delete">
                    <img
                        src={closeIcon}
                        alt="delete"
                        onClick={() => handleDeleteTypeActivity(item._id)}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="type__actions__edit">
                    <img
                        src={editIcon}
                        alt="edit"
                        onClick={() => {
                            setEditType(item);
                            setModalIsOpen(true);
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>
            <div className="type__img">
                {item.coverImage ? (
                    <img src={item.coverImage} alt={item.name} />
                ) : (
                    <div className="type__img__empty"></div>
                )}
            </div>
            <div className="type__text">
                <p className="text-16">{item.name}</p>
            </div>
        </div>
    );

    return (
        <div className="main-container is__grey">
            <HeaderView
                title="Gestionnaire"
                isGoBack={false}
                actions={
                    <IconButton
                        icon={addIcon}
                        text="Ajouter un type d'activité"
                        onClick={() => {
                            setEditType(null);
                            setModalIsOpen(true);
                        }}
                    />
                }
            />

            <TabBar
                tabs={[
                    { name: "Type d'activitée", link: `/manager` },
                    { name: "Catégories parentes", link: `/manager/parents` },
                ]}
                activeTab={0}
            />

            {sortedCategoryKeys.map((category) => (
                <div className="type-container" key={category}>
                    <div className="type-container__content">
                        <h3 className="category-title">{category}</h3>
                        <div className="type__list">
                            {groupedTypes[category].map(renderItemCard)}
                        </div>
                    </div>
                </div>
            ))}

            <ModalAddTypeActivity
                isOpen={modalIsOpen}
                closeModal={() => setModalIsOpen(false)}
                addType={(newTypeData) =>
                    handleAddOrUpdateTypeActivity(editType, newTypeData)
                }
                text={editType ? "Modifier le type d'activité" : "Ajouter un type d'activité"}
                editType={editType}
                categories={parentCategories}
            />
        </div>
    );
};

export default TypeActivityManager;
