import {getRequest, postRequestJson, patchRequest, deleteRequest} from '../request/apiRequest';


const ActivitiesParentTypesServices = {

    getAllParentTypes: async () => {
        return await getRequest('/activity/parent-type');
    },

    getParentTypeById: async (activitiesTypeId) => {
        return await getRequest(`/activity/parent-type/${activitiesTypeId}`);
    },

    createParentType: async (data) => {
        return await postRequestJson('/activity/parent-type', data);
    },

    updateParentType: async (activitiesTypeId, data) => {
        return await patchRequest(`/activity/parent-type/${activitiesTypeId}`, data);
    },

    deleteParentType: async (parentTypeId) => {
        return await deleteRequest(`/activity/parent-type/${parentTypeId}`);
    }
}

export default ActivitiesParentTypesServices;