
import { toZonedTime } from 'date-fns-tz';

const useParseDate = () => {
    // Returns a date string in the format YYYY-MM-DD using the UTC values.
    const parseDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        const options = {
            timeZone: 'UTC', // Use UTC to avoid any conversion
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        // Using formatToParts to build the YYYY-MM-DD string
        const parts = new Intl.DateTimeFormat('fr-FR', options).formatToParts(date);
        const year = parts.find(part => part.type === 'year').value;
        const month = parts.find(part => part.type === 'month').value;
        const day = parts.find(part => part.type === 'day').value;
        return `${year}-${month}-${day}`;
    };

    // Format a full booking date (date and time) using UTC so the time isn't shifted.
    const parseBookingDate = (dateString) => {
        const date = new Date(dateString);

        const dateOptions = {
            timeZone: 'UTC', // use UTC to display exactly what the API sent
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };

        const timeOptions = {
            timeZone: 'UTC', // use UTC to avoid conversion
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };

        const formattedDate =
            new Intl.DateTimeFormat('fr-FR', dateOptions).format(date) +
            ' à ' +
            new Intl.DateTimeFormat('fr-FR', timeOptions).format(date);

        return formattedDate;
    };

    // Format only the date (without time) using UTC.
    const parseBookingDateDay = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'UTC', // use UTC to avoid conversion
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
        return new Intl.DateTimeFormat('fr-FR', options).format(date);
    };

    // Format a slot time from a start time and a duration (in minutes) using UTC.
    const parseSlotTimeBooking = (dateString, durationBooking) => {
        const date = new Date(dateString);
        const dateEnd = new Date(date.getTime() + durationBooking * 60000);
        const options = {
            timeZone: 'UTC', // use UTC so that no additional offset is applied
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };

        const formattedTimeSlot =
            new Intl.DateTimeFormat('fr-FR', options).format(date) +
            ' - ' +
            new Intl.DateTimeFormat('fr-FR', options).format(dateEnd);

        return formattedTimeSlot;
    };

    // Determine whether the current time is within the cancellation period.
    // This function does arithmetic on the raw date values (which are in UTC).
    const parseTimeCancellationPeriodEndBooking = (dateString, cancellationPolicy) => {
        const date = new Date(dateString);
        let dateEnd = new Date(date.getTime());
        if (cancellationPolicy === "72h") {
            dateEnd = new Date(date.getTime() - 72 * 60 * 60 * 1000);
        } else if (cancellationPolicy === "48h") {
            dateEnd = new Date(date.getTime() - 48 * 60 * 60 * 1000);
        } else if (cancellationPolicy === "24h") {
            dateEnd = new Date(date.getTime() - 24 * 60 * 60 * 1000);
        }
        const now = new Date();
        return now >= dateEnd;
    };

    const parseNotificationsDate = (createdAt) => {
        const timeZone = 'Europe/Paris';

        // If createdAt ends with 'Z', assume it's in UTC and convert it;
        // otherwise, assume it is already in the correct time zone.
        const createdAtDate = createdAt.endsWith('Z')
            ? toZonedTime(new Date(createdAt), timeZone)
            : new Date(createdAt);

        // Always convert current time to Paris time
        const nowParis = toZonedTime(new Date(), timeZone);

        const diffMs = nowParis.getTime() - createdAtDate.getTime();
        const diffMins = Math.floor(diffMs / 60000);
        const diffHours = Math.floor(diffMins / 60);
        const diffDays = Math.floor(diffHours / 24);
        const diffMonths = Math.floor(diffDays / 30);

        if (diffMins < 60) {
            return `Reçu il y a ${diffMins} min`;
        } else if (diffHours < 24) {
            return `Reçu il y a ${diffHours} h`;
        } else if (diffDays < 30) {
            return `Reçu il y a ${diffDays} jours`;
        } else {
            return `Reçu il y a ${diffMonths} mois`;
        }
    };


    return {
        parseDate,
        parseBookingDate,
        parseBookingDateDay,
        parseSlotTimeBooking,
        parseTimeCancellationPeriodEndBooking,
        parseNotificationsDate
    };
};

export default useParseDate;