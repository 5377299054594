import { useState } from "react";
import ActivitiesImages from "../../../api/services/activitiesImages";
import ActivitiesServices from "../../../api/services/activities";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userPartnerUpdateActivities } from "../../../redux/slice/authSlice";

const useActivitiesHandler = ({ partnerId, user, setIsModalOpen, showError, setLoading }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const uploadImages = async (activityId, localImages) => {
        const successfullyUploaded = [];
        const failedUploads = [];

        for (const [index, image] of localImages.entries()) {
            try {
                const blob = await fetch(image.preview).then((res) => {
                    if (!res.ok) throw new Error("Failed to fetch image");
                    return res.blob();
                });
                const file = new File([blob], image.name, { type: "image/webp" });

                if (!file || file.size === 0) {
                    console.warn("Skipping invalid file:", file);
                    failedUploads.push(image.preview);
                    continue;
                }

                const formData = new FormData();
                formData.append("image", file);
                if (index === localImages.length - 1) {
                    formData.append("isLastImage", true);
                }

                const response = await ActivitiesImages.create(activityId, formData, partnerId);
                if (response.status === 200) {
                    successfullyUploaded.push(image.preview);
                } else {
                    failedUploads.push(image.preview);
                }
            } catch (error) {
                console.error(`Error uploading image (${image.preview}):`, error);
                failedUploads.push(image.preview);
            }
        }

        if (failedUploads.length > 0) {
            showError(`Some images failed to upload: ${failedUploads.join(", ")}`);
        }
        return successfullyUploaded.length === localImages.length;
    };

    const addActivity = async (activityData, localImages) => {
        setLoading(true);
        try {
            const response = await ActivitiesServices.createActivity(partnerId, activityData);
            if (response.status === 200) {
                const activityId = response.data.activity._id;

                if (localImages?.length) {
                    const allImagesUploaded = await uploadImages(activityId, localImages);
                    if (!allImagesUploaded) {
                        showError("Some images could not be uploaded.");
                    }
                }

                if (user.role === "partner" || user.role === "partners-admin") {
                    dispatch(userPartnerUpdateActivities({ partnerID: partnerId, activity: activityId }));
                }
            } else {
                showError("Failed to create activity.");
            }
        } catch (error) {
            showError(error.message || "An error occurred while creating the activity.");
        } finally {
            setLoading(false);
            setIsModalOpen(true);
        }
    };

    const updateActivity = async (activityId, activityData, localImages, isAlreeadyWaiting = false, isDraft = false) => {
        setLoading(true);
        try {
            if (localImages?.length) {
                await uploadImages(activityId, localImages);
            }

            const response = await ActivitiesServices.updateActivity(activityId, partnerId, activityData);
            if (response.status === 200) {
                console.log('update');
            } else {
                showError("Failed to update activity.");
            }
        } catch (error) {
            showError(error.message || "An error occurred while updating the activity.");
        } finally {
            setLoading(false);
            isAlreeadyWaiting && !isDraft ? setIsModalOpen(true) :  navigate(`/partners/${partnerId}/activities`);
        }
    };

    const saveDraftActivity = async (draftData, localImages) => {
        setLoading(true);
        try {
            const response = await ActivitiesServices.saveDraftActivity(partnerId, draftData);
            if (response.status === 200) {

                const activityId = response.data.draftActivity.id;

                if (localImages?.length) {
                    const allImagesUploaded = await uploadImages(activityId, localImages);
                    if (!allImagesUploaded) {
                        showError("Some images could not be uploaded.");
                    }
                }


                navigate(`/partners/${partnerId}/activities`);
            } else {
                showError("Failed to save draft activity.");
            }
        } catch (error) {
            showError(error.message || "An error occurred while saving the draft.");
        } finally {
            setLoading(false);
        }
    };

    const deleteActivity = async (activityId) => {
        setLoading(true);
        try {
            const response = await ActivitiesServices.deleteActivity(activityId, partnerId);
            if (response.status === 200) {
                navigate(`/partners/${partnerId}/activities`);
            } else {
                showError("Failed to delete activity.");
            }
        } catch (error) {
            showError(error.message || "An error occurred while deleting the activity.");
        } finally {
            setLoading(false);
        }
    };

    return { addActivity, updateActivity, saveDraftActivity, deleteActivity };
};

export default useActivitiesHandler;
